import React from "react";
import Reset from "../components/resetpasswd/Reset";

const ResetPassword = () => {
  return (
    <div>
      <Reset />
    </div>
  );
};

export default ResetPassword;
