import React from "react";
import ProductCard from "./ProductCard";

const ProductListing = () => {
  return (
    <div>
      <ProductCard />
    </div>
  );
};

export default ProductListing;
